window.onload = function() {
    const navContainers = document.querySelectorAll('.link-tabs-container');

    navContainers.forEach(function(navContainer) {
        const linkTabSection = document.createElement('div');
        linkTabSection.className = 'link-tab-section';
        navContainer.parentNode.insertBefore(linkTabSection, navContainer);
        linkTabSection.appendChild(navContainer);

        const arrowMainContainer = document.createElement('div');
        arrowMainContainer.className = 'nav-arrows';
        const arrowContainerPrev = document.createElement('div');
        arrowContainerPrev.className = 'nav-arrows-container prev';
        const arrowContainerNext = document.createElement('div');
        arrowContainerNext.className = 'nav-arrows-container next';

        const arrowPrev = document.createElement('button');
        arrowPrev.innerHTML = '<i class="fa-solid fa-chevron-left"></i>';
        arrowPrev.className = 'nav-arrow prev';
        const arrowNext = document.createElement('button');
        arrowNext.innerHTML = '<i class="fa-solid fa-chevron-right"></i>';
        arrowNext.className = 'nav-arrow next';

        arrowContainerPrev.appendChild(arrowPrev);
        arrowContainerNext.appendChild(arrowNext);
        arrowMainContainer.appendChild(arrowContainerPrev);
        arrowMainContainer.appendChild(arrowContainerNext);
        linkTabSection.appendChild(arrowMainContainer);

        function updateArrowVisibility() {
            const containerWidth = navContainer.offsetWidth;
            const totalWidth = navContainer.scrollWidth;
            const activeTab = navContainer.querySelector('.link-tab > li.active');

            if (activeTab) {
                const activeTabRightEdge = activeTab.offsetLeft + activeTab.offsetWidth;
                const isLastTab = navContainer.querySelector('.link-tab > li:last-child') === activeTab;

                if (isLastTab && activeTabRightEdge > navContainer.scrollLeft + containerWidth) {
                    navContainer.scrollLeft = totalWidth - containerWidth;
                }
            }

            if (navContainer.scrollLeft <= 0) {
                arrowPrev.style.display = 'none';
                arrowMainContainer.classList.add('at-left-edge');
            } else {
                arrowPrev.style.display = 'block';
                arrowMainContainer.classList.remove('at-left-edge');
            }

            if (navContainer.scrollLeft + containerWidth >= totalWidth) {
                arrowNext.style.display = 'none';
                arrowMainContainer.classList.add('at-right-edge');
            } else {
                arrowNext.style.display = 'block';
                arrowMainContainer.classList.remove('at-right-edge');
            }
        }

        arrowNext.addEventListener('click', function() {
            const scrollAmount = navContainer.offsetWidth / 2;
            navContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            updateArrowVisibility();
        });

        arrowPrev.addEventListener('click', function() {
            const scrollAmount = -navContainer.offsetWidth / 2;
            navContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            updateArrowVisibility();
        });

        navContainer.addEventListener('scroll', updateArrowVisibility);
        window.addEventListener('resize', updateArrowVisibility);

        updateArrowVisibility();
    });
};
